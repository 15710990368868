

























































































































































import { Component } from "vue-property-decorator"
import ViewWrapper from "@/views/ViewWrapper.vue"
import SectionUnderLine from "@/components/Global/SectionUnderLine.vue"
import _ from "lodash"
import ContactUs from "@/types/ContactUs/ContactUs"
import ContactUsApi from "@/api/ContactUs/ContactUsApi"
import { mixins } from "vue-class-component"
import InputMixin from "@/types/InputMixin"
import BreakpointMixin from "@/types/BreakpointMixin"

@Component({
  name: "ContactUsComponent",
  components: { SectionUnderLine, ViewWrapper }
})
export default class ContactUsComponent extends mixins(InputMixin, BreakpointMixin) {
  private model = new ContactUs()

  private submitting = false

  private successDialog = false

  private acceptingFileTypes = [
    "image/*",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/pdf"
  ]

  private rules = {
    required: (value: string) => !!value || "Required.",
    email: (value: string) => {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(value) || "อีเมลไม่ถูกต้อง"
    },
    phone: (value: string) => {
      const pattern = /^[0-9|-]*$/
      return pattern.test(value) || "เบอร์โทรศัพท์ไม่ถูกต้อง"
    }
  }

  get textAreaHeight() {
    const bp = this.$vuetify.breakpoint
    if (bp.lgAndDown) {
      return "180px"
    }
    return "217px"
  }

  // eslint-disable-next-line class-methods-use-this
  toggleFileInput() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const elm = document.getElementById("fileInput")!
    elm.click()
  }

  submit() {
    this.submitting = true
    ContactUsApi.submitForm(this.model)
      .then(() => {
        // eslint-disable-next-line no-alert
        // alert('ส่งข้อความสำเร็จ');
        this.successDialog = true
        this.model = new ContactUs()
      })
      .finally(() => {
        this.submitting = false
      })
  }

  get formCompleted(): boolean {
    const validRequired = _.every(this.formTemplate, ({ value, required }) => {
      // @ts-ignore
      if (required) return !!this.model[value]
      return true
    })
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const validPhone = this.rules.phone(this.model.tel!) === true
    let validEmail = true
    if (this.model.email) validEmail = this.rules.email(this.model.email!) === true
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return validPhone && validEmail && validRequired
  }

  get acceptFileTypeString(): string {
    return _.join(this.acceptingFileTypes, ",")
  }

  private formTemplate = [
    {
      label: "fullname",
      value: "name",
      type: "text",
      required: true,
      rules: [this.rules.required]
    },
    {
      label: "เบอร์โทรศัพท์",
      value: "tel",
      type: "text",
      textType: "number",
      required: true,
      rules: [this.rules.required, this.rules.phone]
    },
    {
      label: "อีเมล",
      value: "email",
      type: "text",
      required: false,
      rules: [this.rules.email]
    },
    {
      label: "เรื่องที่ต้องการติดต่อ",
      value: "subject",
      type: "text",
      rules: []
    },
    {
      label: "รายละเอียดข้อความ",
      value: "detail",
      type: "textarea",
      required: true,
      rules: [this.rules.required]
    },
    {
      label: "เลือกไฟล์แนบ",
      value: "file",
      type: "file"
    }
  ]
}
