import { JsonProperty, Serializable } from "typescript-json-serializer"

@Serializable()
export default class ContactUs {
  @JsonProperty() name?: string

  @JsonProperty() email?: string

  @JsonProperty() tel?: string

  @JsonProperty() subject?: string

  @JsonProperty() detail?: string

  @JsonProperty() file?: File

  @JsonProperty() lang?: string = "TH"
}
