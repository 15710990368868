import { render, staticRenderFns } from "./ContactUsPage.vue?vue&type=template&id=d86974b2&scoped=true&"
import script from "./ContactUsPage.vue?vue&type=script&lang=ts&"
export * from "./ContactUsPage.vue?vue&type=script&lang=ts&"
import style0 from "./ContactUsPage.vue?vue&type=style&index=0&id=d86974b2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d86974b2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VHover } from 'vuetify/lib/components/VHover';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
installComponents(component, {VCol,VContainer,VFadeTransition,VHover,VRow,VSelect,VTab,VTabItem,VTabs,VTabsItems,VTabsSlider})
